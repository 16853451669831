import React from "react";

const RegisterStartSuccess  = ()=>{

    return <>
        Bitte überprüfe dein Email Postfach und klicke auf den bestätigungslink den
        du von uns erhalten hast.
    </>
};

export default RegisterStartSuccess;