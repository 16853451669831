import React from "react";

const LoginSuccess = () => {

    return <>
        du hast dich erfolgreich eingelogged.

        {/*    show list of possible targets.*/}
    </>
};

export default LoginSuccess;