import React from "react";
import "./Login.css";
import LoginForm from "./LoginForm";
import Footer from "../footer/Footer";
import Header from "../shared/Header";
import VerticalSpacing from "../util/Spacing";
import Page from "../shared/Page";
import BackArrow from "../BackArrow/BackArrow";

const Login = () => {

    return <>
        <Page>

            <Header/>
            <BackArrow/>
            <LoginForm/>

            <VerticalSpacing height={64}/>
            <Footer/>

        </Page>
    </>
};

export default Login;