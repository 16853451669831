// import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Id from "./components/id/Id";
import Error404 from "./components/error/Error404";
import RegisterStart from "./components/register-start/RegisterStart";
import Login from "./components/login/Login";
import RegisterStartSuccess from "./components/register-start/RegisterStartSuccess";
import LoginSuccess from "./components/login-success/LoginSuccess";
import Logout from "./components/logout/Logout";
import LogoutFinished from "./components/logout-finished/LogoutFinished";
import OTP from "./components/otp/OTP";
import User from "./components/user/User";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Id/>}/>
                <Route path="/register/start" element={<RegisterStart/>}/>
                <Route path="/register/start/success" element={<RegisterStartSuccess/>}/>

                <Route path="/login" element={<Login/>}/>
                <Route path="/login/success" element={<LoginSuccess/>}/>

                <Route path="/login/success" element={<LoginSuccess/>}/>
                <Route path="/login/success" element={<LoginSuccess/>}/>
                <Route path="/login/success" element={<LoginSuccess/>}/>

                <Route path="/logout" element={<Logout/>}/>
                <Route path="/logout/finished" element={<LogoutFinished/>}/>

                <Route path="/user" element={<User/>}/>

                {/* todo: implement later when the api for this is finished. */}
                <Route path="/otp" element={<OTP/>}/>

                <Route path="*" element={<Error404/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
