import React, {useState} from "react";
import "./UsernameInput.css";
import FormErrorBox from "../../error/FormErrorBox";

const UsernameInput = ({onChangeHandler, value}) => {

    const [currentError, setCurrentError] = useState(null);

    const runValidation = (email) => {
        console.log("I left  and will be validated", email)

        if (!email.includes("@")) {
            setCurrentError("Fehler. Bitte gib eine valide E-Mail Adresse an")

        } else {
            setCurrentError(null);

        }
    };

    return <>
        <div className="username-input">
            <label className="username-input__label" htmlFor="email">E-Mail</label>
            <FormErrorBox errorMessage={currentError}/>
            <input className="username-input__text-input"
                   value={value ? value : ""}
                   onChange={(event) => {
                       if (onChangeHandler) {
                           onChangeHandler(event.target.value)
                       }
                   }}
                   onBlur={(event) => {
                       runValidation(event.target.value)
                   }}
                   id="email"
                   name="email"
                   type="text"/>
        </div>
    </>;
};

export default UsernameInput;