import React, {useState} from "react";
import "./LoginForm.css";
import UsernameInput from "./inputs/UsernameInput";
import PasswordInput from "./inputs/PasswordInput";
// import StayLoggedInCheck from "./inputs/StayLoggedInCheck";
import VerticalSpacing from "../util/Spacing";
import LoginButton from "./loginButton/LoginButton";
import GoToRegistrationButton from "./goToRegistrationButton/GoToRegistrationButton";
import FormErrorBox from "../error/FormErrorBox";
import SessionCookie from "../../service/SessionCookie";

const LoginForm = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [errorMessage, setErrorMessage] = useState("");


    const handleOnLogin = () => {
        console.log("Try to login")

        // early exit to show error.
        if (email === "" && password === "") {
            setErrorMessage("Benutzername oder Passwort fehlen.")
            return;
        }
        setErrorMessage(null);
        sendLoginRequest(email, password);
    };


    const sendLoginRequest = (user, pass) => {
        const userBase64 = btoa(user);
        const passBase64 = btoa(pass);

        fetch("http://localhost:8080/login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'EMAIL': userBase64,
                'PASSWORD': passBase64
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("successful login", data)
                const token = data.accessToken;

                SessionCookie.safeLoginToken(token);

            })
            .catch(error => {
                console.log("well thats an error", error)
            });
    };

    return <>
        <div className="login-form">

            <h1 className="login-form__headline">Login</h1>

            <FormErrorBox errorMessage={errorMessage}/>
            <UsernameInput value={email}
                           onChangeHandler={(email) => {
                               console.log("email is:", email)
                               setEmail(email)
                           }}
            />
            <VerticalSpacing height={16}/>
            <PasswordInput value={password}
                           onChangeHandler={(password) => {
                               console.log("pasword ", password)
                               setPassword(password)
                           }}/>
            <VerticalSpacing height={12}/>

            {/*<StayLoggedInCheck/>*/}

            <VerticalSpacing height={32}/>
            <LoginButton onLogin={() => {
                handleOnLogin();
            }}/>

            <VerticalSpacing height={16}/>

            <div className="login-form__or-text">ODER</div>
            <VerticalSpacing height={16}/>
            <GoToRegistrationButton/>

        </div>
    </>;
};

export default LoginForm;