import React from "react";
import "./FormErrorBox.css";

const FormErrorBox = ({errorMessage}) => {

    return <div className="form-error-box">
        {errorMessage ? errorMessage : null}
    </div>
};

export default FormErrorBox;
