import React, {useEffect} from "react";
import Header from "../shared/Header";
import Page from "../shared/Page";
import Welcome from "./welcome/Welcome";
import SessionCookie from "../../service/SessionCookie";

import config from "../../configuration/config";

const Id = () => {

    useEffect(() => {
        console.log("Look at the current configurations: ",config.API_URL, config.DEBUG_MODE)
    }, []);


    const goToRegisterStart = () => {
        window.location.href = "/register/start";
    };

    const goToLogin = () => {
        window.location.href = "/login";
    };

    const loginCheck = () => {
        const tokenIs = SessionCookie.readLoginToken();

        console.log("token: ", tokenIs);
    };

    return <>
        <Page>
            <Header/>

            <Welcome/>
            <div>test</div>

            <div>welcome to gehrbeck id</div>
            <br/>
            <div>
                <button onClick={goToLogin}>Bring me to the login</button>
                <button onClick={goToRegisterStart}>I dont have an account yet</button>

                <button onClick={loginCheck}>Check login state using cookie</button>
            </div>
        </Page>
    </>;
}

export default Id;
