import React, {useState} from "react";
import "./PasswordInput.css";
import FormErrorBox from "../../error/FormErrorBox";

const PasswordInput = ({onChangeHandler, value}) => {

    const [currentError, setCurrentError] = useState(null);

    const validatePassword = (password) => {
        if (password === "") {
            setCurrentError("Bitte gib ein Passwort ein um fortzufahren.")
        } else {
            setCurrentError(null);
        }
    };

    return <>
        <div className="password-input">
            <label className="password-input__label" htmlFor="password">Passwort</label>
            <FormErrorBox errorMessage={currentError}/>
            <input className="password-input__text-input"
                   onChange={(event) => {
                       if (onChangeHandler) {
                           onChangeHandler(event.target.value);
                       }
                   }}

                   onBlur={(event) => {
                       validatePassword(event.target.value);
                   }}

                   value={value ? value : ""}
                   id="password"
                   name="password"
                   type="password"/>
        </div>
    </>
};

export default PasswordInput;