import React from "react";

const Error404 = ()=>{

    return <>
        <div>Error 404</div>

        <div>Die angefragte seite konnte leider nicht gefunden werden.</div>
    </>

};

export default Error404;
