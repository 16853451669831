import React from "react";
import "./RegisterStartButton.css";

const RegisterStartButton = ({onClick}) => {

    return <>
        <div className="register-start-button">
            <button onClick={() => {
                onClick()
            }} className="register-start-button__input">Registrieren
            </button>
        </div>
    </>
};

export default RegisterStartButton;