import React from "react";
import "./Footer.css";

const Footer  =()=>{


    return <>
        <div className="footer">footer</div>
    </>
};

export default Footer;