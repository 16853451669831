import React from "react";
import "./GoToLoginButton.css";

const GoToLoginButton = () => {

    const handleClick = () => {
        window.location.href = "/login";
    };

    return <>
        <div className="go-to-login-button">
            <button onClick={() => {
                handleClick()
            }} className="go-to-login-button__input">Zum Login
            </button>
        </div>
    </>;
};

export default GoToLoginButton;