import React from "react";
import SessionCookie from "../../service/SessionCookie";
import Page from "../shared/Page";

const User = () => {

    const readCurrentUserInformation = () => {

        const token = SessionCookie.readLoginToken();

        fetch("http://localhost:8080/user/uuid", {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                'TOKEN': btoa(token)
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("sucess", data)
            })
            .catch(error => {
                console.error("unexpected error", error)
            });
    };

    return <>
        <Page>

            Dein Benutzer
            <button onClick={readCurrentUserInformation}>get user data</button>
            - benutzer löschen
            - passwort ändern
            - meine Daten herunterladen
            - meine Daten löschen (falls benutzer seinen account behalten und seine daten entfernt haben möchte.)
        </Page>
    </>
};

export default User;