import ApplicationConfigUtil from "../components/util/ApplicationConfigUtil";
import CookieUtil from "../components/util/CookieUtil";

class SessionCookie {

    static safeLoginToken(token) {

        // section: let the cookie expire after a week
        let expirationTime;

        let expirationDate = new Date();
        expirationDate.setHours(24 * 7);

        expirationTime = expirationDate.toUTCString();

        console.log("trying to safe cookie")

        if (ApplicationConfigUtil.isRunningOnProdEnvironment()) {
            document.cookie = `accessToken=${token}; domain=.gehrbeck.com; expires=${expirationTime}`;
        } else {
            document.cookie = `accessToken=${token}; domain=.localhost; expires=${expirationTime}`;
        }
    }

    static readLoginToken() {
        const accessToken = CookieUtil.getCookie(`accessToken`);

        if (accessToken === null) {
            console.error("accessToken not found. You are not logged in.");
        }

        return accessToken;
    }

}

export default SessionCookie;