import React, {useState} from "react";
import "./RegisterStart.css";
import Page from "../shared/Page";
import Header from "../shared/Header";
import VerticalSpacing from "../util/Spacing";
import RegisterStartButton from "./RegisterStartButton";
import GoToLoginButton from "./goToLoginButton/GoToLoginButton";
import BackArrow from "../BackArrow/BackArrow";

const RegisterStart = () => {
    const [emailValue, setEmailValue] = useState("");
    const [validationIssue, setValidationIssue] = useState(null);

    const handleRegisterStart = () => {
        console.log("something happened")

        // todo: validate email. if invalid show error
    };

    return <>
        <Page>
            <Header/>
            <BackArrow link="/"/>
            <div className="register-start">
                <h1 className="register-start__headline">Registrieren</h1>
                <p className="register-start__explanation-text">Um ein Konto für Dich anzulegen benötigen wir Deine
                    E-Mail Adresse. <br/>Wir senden Dir einen Bestätigungslink über den der login validiert wird.</p>

                <div className="register-start__email">
                    <label className="register-start__email__label">E-Mail</label>
                    <input className="register-start__email__input"
                           value={emailValue}
                           onChange={(event) => {
                               setEmailValue(event.target.value)
                           }}
                           type="email"/>
                </div>

                <VerticalSpacing height={32}/>
                <RegisterStartButton onClick={() => {
                    handleRegisterStart();
                }}/>
                <VerticalSpacing height={16}/>

                <div className="register-start__or-text">ODER</div>
                <VerticalSpacing height={16}/>

                <GoToLoginButton/>
            </div>
        </Page>
    </>


};

export default RegisterStart;