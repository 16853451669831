import React from "react";
import "./Page.css";

const Page = ({...props}) => {

    return <>
        <div className="page">
            <div className="page__gradient-green"></div>
            <div className="page__gradient-blue"></div>
            <div className="page__gradient-purple"></div>

            <div className="page__content">
                {props.children ? props.children : null}
            </div>
        </div>
    </>
}

export default Page;