class CookieUtil {

    static areCookiesEnabled() {
        document.cookie = "testCookieEnabled=true";
        return document.cookie.indexOf("testCookieEnabled=true") !== -1;
    }

    static getCookie(cookieName) {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');

        for (const cookie of cookies) {
            const [name, value] = cookie.split('=');
            if (name === cookieName) {
                return decodeURIComponent(value);
            }
        }

        return null; // Cookie not found
    }
};

export default CookieUtil;