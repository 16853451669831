import React from "react";
import "./Header.css";

const Header = () => {

    return <>
        <div className="header">
            <img className="header__logo" src="https://cdn.gehrbeck.com/logo/gehrbeck-logo.svg" alt="gehrbeck logo"/>
            <img className="header__logo-text" src="https://cdn.gehrbeck.com/logo/gehrbeck-logo-text.svg" alt="gehrbeck logo"/>
            <span className="header__text-colon header__text--header-text">:</span>
            <span className="header__text header__text--header-text">ID</span>
        </div>
    </>;
};

export default Header;