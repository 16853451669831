import React from "react";
import "./BackArrow.css";

const BackArrow = ({link = "/"}) => {

    const handleOnClick = () => {
        console.log("trying to go back.");
        window.location.href = link;
    };

    return <>
        <div className="back-arrow" onClick={() => {
            handleOnClick()
        }}>
            <img className="back-arrow__image"
                 src="https://cdn.gehrbeck.com/icons/simple_arrow_symbol.svg"
                 alt="back-arrow"/>
        </div>
    </>;
};

export default BackArrow;