import React from "react";
import "./GoToRegistrationButton.css";

const GoToRegistrationButton = () => {

    const handleClick = () => {
        window.location.href = "/register/start";
    };

    return <>
        <div className="go-to-registration">
            <button onClick={()=>{handleClick()}} className="go-to-registration__button">Benutzer Erstellen</button>
        </div>
    </>;
};

export default GoToRegistrationButton;