import React from "react";
import "./Welcome.css";

const Welcome = () => {

    return <>
        <div>Welcome</div>
    </>
};

export default Welcome;