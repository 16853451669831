import React from "react";
import "./LoginButton.css";

const LoginButton = ({onLogin}) => {

    return <>
        <div className="login-button-wrapper">
            <button onClick={() => {
                if (onLogin) {
                    onLogin();
                }
            }} className="login-button-wrapper__login-button">Einloggen
            </button>
        </div>
    </>
};

export default LoginButton;